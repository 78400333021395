<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000px"
    origin="left top"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text class="mb-6">
        <v-form ref="form" v-model="valid" lazy-validation>
          <h3 class="mt-4 mb-2">Informações</h3>
          <v-row>
            <v-col cols="7" md="6">
              <v-radio-group
                class="mt-0"
                hide-details="auto"
                v-model="form.person_type"
                row
              >
                <v-radio label="Pessoa Física" value="fisica"> </v-radio>
                <v-radio label="Pessoa Jurídica" value="juridica"> </v-radio>
              </v-radio-group>
            </v-col>
            <v-col offset-md="4" md="2">
              <app-code-field
                label="Código"
                v-model="form.code"
                :clear-on-auto-code="isInsert"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <app-text-field v-model="form.name" :label="name_label" />
            </v-col>
            <v-col md="6">
              <app-text-field v-model="form.alias" :label="alias_label" />
            </v-col>
          </v-row>
          <template v-if="IsJuridic">
            <v-row>
              <v-col md="6">
                <app-cnpj-field
                  @company="setCompanyData($event)"
                  v-model="form.cnpj"
                  :isInsert="isInsert"
                  label="CNPJ"
                />
              </v-col>
              <v-col md="4">
                <app-text-field
                  :disabled="form.inscricao_estadual_isento == 1"
                  v-model="form.inscricao_estadual"
                  label="Inscrição Estadual"
                />
              </v-col>
              <v-col md="2">
                <v-checkbox
                  class="mt-0"
                  v-model="form.inscricao_estadual_isento"
                  label="Isento"
                  hide-details="auto"
                  :true-value="1"
                  :false-value="0"
                />
              </v-col>
            </v-row>
          </template>

          <template v-if="!IsJuridic">
            <v-row>
              <v-col md="6">
                <app-cpf-field v-model="form.cpf" label="CPF" />
              </v-col>
              <v-col md="6">
                <app-rg-field v-model="form.rg" label="RG" />
              </v-col>
            </v-row>
          </template>
          <h3 class="mt-10 mb-6">Endereços</h3>
          <AddressForm v-model="form.main_address" />

          <h3 class="mt-10 mb-4">Telefones</h3>
          <v-row>
            <v-col>
              <PhoneContactsForm v-model="form.phones" />
            </v-col>
          </v-row>
          <h3 class="mt-10 mb-4">Emails e sites</h3>
          <v-row>
            <v-col>
              <EmailContactsForm v-model="form.emails" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <SiteContactsForm v-model="form.sites" />
            </v-col>
          </v-row>
          <h3 class="mt-10 mb-4">Comentários</h3>
          <v-row>
            <v-col>
              <CommentList v-model="form.comments" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-status-switch v-model="form.status" />
            </v-col>
          </v-row>
        </v-form>
        <v-row class="mt-5">
          <v-col>
            <v-card color="backgroud" elevation="0">
              <app-activities v-if="!isInsert" :activities="form.activities" />
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
        >
          Cancelar
        </v-btn>
        <v-btn @click="handleSave()" class="text-capitalize" color="primary">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PhoneContactsForm from "@/components/app/forms/PhoneContactsForm.vue";
import EmailContactsForm from "@/components/app/forms/EmailContactsForm.vue";
import SiteContactsForm from "@/components/app/forms/SiteContactsForm.vue";
import AddressForm from "@/components/app/forms/AddressForm.vue";
import CommentList from "@/components/comments/CommentList.vue";

export default {
  components: {
    PhoneContactsForm,
    EmailContactsForm,
    SiteContactsForm,
    AddressForm,
    CommentList,
  },
  data() {
    return {
      dialog: false,

      statusText: {
        1: "Este cadastro está ativo",
        0: "Este cadastro está inativo",
      },
      rules: {
        required: (value) => !!value || "Required.",
      },
      title: "Cadastrar Fornecedor",

      valid: false,
      form: {},
      baseForm: {
        code: null,
        person_type: "juridica",
        name: null,
        alias: null,
        cpf: null,
        cnpj: null,
        rg: null,
        status: 1,
        inscricao_estadual: null,
        inscricao_estadual_isento: null,
        main_address: {
          address: null,
          number: null,
          complement: null,
          district: null,
          postal_code: null,
          city_district: null,
          city: null,
          state: null,
          country: null,
          type: "mainAddress",
        },
        phones: [],
        emails: [],
        sites: [],
        comments: [],
      },

      supplierData: {},
    };
  },

  computed: {
    IsJuridic() {
      return this.form.person_type == "juridica";
    },
    alias_label() {
      if (this.IsJuridic) {
        return "Nome Fantasia";
      } else {
        return "Apelido";
      }
    },

    name_label() {
      if (this.IsJuridic) {
        return "Razão Social";
      } else {
        return "Nome";
      }
    },
    isInsert() {
      return !this.form.id;
    },
  },
  created() {
    this.reset();
  },

  methods: {
    async open(id) {
      this.reset();
      if (id) {
        await this.showSupplier(id);
      }

      this.setDefaults();

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.title = "Cadastrar Fornecedor";
    },

    setDefaults() {
      this.form.phones.push({
        contact: null,
      });

      this.form.emails.push({
        contact: null,
      });

      if (this.form.sites.length == 0) {
        this.form.sites.push({
          contact: null,
        });
      }
    },

    async showSupplier(id) {
      this.title = "Atualizar Fornecedor";

      this.$store.commit("app/showLoading");

      await this.$http.$get("supplier/supplier/" + id).then((response) => {
        this.form = response.supplier;
        this.$store.commit("app/hideLoading");
      });
    },

    handleSave() {
      this.$refs.form.validate();
      if (this.valid == true) {
        if (this.isInsert) {
          this.store();
        } else {
          this.update();
        }
      }
    },

    store() {
      this.$http.store("supplier/supplier", this.form).then((response) => {
        this.processSaved(response);
      });
    },

    update() {
      this.$http
        .update("supplier/supplier", this.form.id, this.form)
        .then((response) => {
          this.processSaved(response);
        });
    },

    processSaved(response) {
      this.$emit("store", response);
      this.dialog = false;
    },

    setCompanyData(company) {
      this.form = {
        ...this.form,
        ...company,
      };
    },
    addSupplier(data) {
      this.reset();
      this.form = data;
      this.dialog = true;
    },
  },
};
</script>

<style>
</style>
